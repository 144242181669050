.wrapper {
  border: 1px solid grey !important;
}

.editor {
  background-color: white !important;
  height: 300px !important;
  color: black !important;
  line-height: 0.7;
}

.toolbar {
  background-color: rgb(235, 235, 235) !important;
}
