.download-table-xls-button{
    color: #fff !important;
    border: none !important;
    background-color:transparent !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}
.content{
    width: 750px !important;
    height: 842px !important;
}
.pop{
    
    width: 7%;
    font-size: 8px !important;
}
/* .last{
    width: 5% !important;
} */
.non2{
    display: none !important;
}
.btn:hover{
    background:none !important;
    box-shadow: none !important;
}
.btn{
    padding: 3px !important;
    box-shadow: none !important;
    cursor: pointer !important;
    text-transform: none !important;
}